* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 999px) {
  body,
  html {
    overflow-x: hidden;
  }
}

.x-modal {
  padding: 10px;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.x-modal-cover {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
  cursor: pointer;
  z-index: 1001;
  background: #525252;
}
.x-modal-content {
  background-color: #ffffff;
  padding: 10px;
  position: relative;
  z-index: 1001;
  border-radius: 10px;
  max-width: 320px;
  text-align: center;
}
.x-modal-body {
  padding: 10px;
}
.x-modal-close {
  position: absolute;
  right: 0;
  width: 36px;
  height: 36px;
  top: -12px;
  background: #fff;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.x-modal-close svg {
  width: 100%;
}

.general-modal-content {
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #ffffff;
  padding: 10px;
  position: relative;
  z-index: 1001;
  border-radius: 10px;
}

.general-modal-close {
  position: absolute;
  right: 0;
  width: 36px;
  height: 36px;
  top: 0px;
  background: #fff;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}
.general-modal-close svg {
  width: 100%;
}
